import React from 'react';
import { PortableText } from '@portabletext/react';
import ArrowAngle from '../../../svg/ArrowAngle';
import {GatsbyImage} from 'gatsby-plugin-image'
import * as styles from './linkBlock.module.scss'
import ButtonTemplate from '../buttonTemplate/buttonTemplate';
import Link from '../../../Link/Link';

const LinkBlock = props => {
    const { data, themeContext = 'appliedSciences', pageContext } = props;
    const customComponents = {
        marks: {
            footnote: ({children}) => <span><sup>{children}</sup></span>
        }
    }
    // console.log('pageContext', pageContext)
    // console.log(data);
    return (
        <>
            <div className={styles.linkBlock + " " + (themeContext === 'appliedSciences' ? styles.appSci : themeContext === 'stansonHealth' ? styles.stanson : styles.homePage)
                                             + " " + (pageContext === 'about' ? styles.detailedLink : '')
                                             + " " + (pageContext?.includes('authorizations') || pageContext?.includes('coding') ? styles.smallSpacing : '')
                                             + " " + (pageContext?.includes('clinical-decision-support') ? styles.decisionSupport : '')}>
                <div className={styles.imageContainer}>
                    {data.imageLink ? 
                    <Link to={data.imageLink}>
                        <GatsbyImage image={data.image?.asset.gatsbyImageData} alt={data.altText || data.title}/>
                    </Link>
                    :
                    <GatsbyImage image={data.image?.asset.gatsbyImageData} alt={data.altText || data.title}/>
                    }
                </div>
                <div className={styles.textContainer}>
                    <header className={styles.titleContainer}>
                        <h2>{data.title}</h2>
                    </header>
                    <div className={styles.descriptionContainer}>
                        <PortableText value={data._rawDescription} components={customComponents}/>
                    </div>
                    {data.button && data.button.buttonText &&
                        <div className={styles.buttonContainer}>
                            <ButtonTemplate data={data.button}>
                                {data.button.buttonText} <ArrowAngle color={themeContext === 'appliedSciences' ? '#034E6E' : themeContext === 'stansonHealth' ? '#22827C' : '#DA437E'}/>
                            </ButtonTemplate>
                        </div>
                    }
                </div>
            </div>
            {data._rawFootnote && 
            <div className={styles.footnoteContainer}>
                <PortableText value={data._rawFootnote} components={customComponents}/>
            </div>
            }
        </>
    )
}

export default LinkBlock