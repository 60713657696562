// extracted by mini-css-extract-plugin
export var appSci = "linkBlock-module--appSci--0f144";
export var appSciStyles = "linkBlock-module--appSciStyles--dc558";
export var buttonContainer = "linkBlock-module--buttonContainer--518f2";
export var decisionSupport = "linkBlock-module--decisionSupport--178a5";
export var descriptionContainer = "linkBlock-module--descriptionContainer--c9393";
export var detailedLink = "linkBlock-module--detailedLink--75321";
export var footnoteContainer = "linkBlock-module--footnoteContainer--09958";
export var homePage = "linkBlock-module--homePage--b4e48";
export var homeStyles = "linkBlock-module--homeStyles--53baa";
export var imageContainer = "linkBlock-module--imageContainer--74f93";
export var linkBlock = "linkBlock-module--linkBlock--1e640";
export var smallSpacing = "linkBlock-module--smallSpacing--b5ece";
export var stanson = "linkBlock-module--stanson--94f7c";
export var textContainer = "linkBlock-module--textContainer--74dfb";
export var titleContainer = "linkBlock-module--titleContainer--556c2";